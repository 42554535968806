<template>
    <div>
        <b-modal v-model="isOpen" content-class="rounded-0" header-class="border-0" header-bg-variant="light" size="xl"
            centered no-close-on-backdrop hide-footer>
            <template #modal-header>
                <div class="modal-custom-title">
                    <h5 class="modal-title">
                        Listado de registros desvinculados y/o renuncias
                    </h5>
                </div>
            </template>
            <div>
                <b-row class="justify-content-end mb-3">
                    <b-col xl="4" md="6" cols="6">
                        <b-input-group class="mt-1">
                            <b-form-input placeholder="Buscar" class="rounded-0" size="sm" v-model="search"
                                @keydown.enter="filterTable" />
                            <b-input-group-append>
                                <b-button class="main-btn" size="sm" @click="filterTable">
                                    <b-icon-search />
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-card no-header no-body class="rounded-0 shadow-sm border-0">
                            <b-table stacked="md" small hover select-mode="single" selectable show-empty
                                empty-text="No hay personas jurídicas que mostrar" :filter="filter" :busy.sync="isBusy"
                                :fields="fields" :items="retrieveData" :current-page="currentPage" :per-page="take"
                                @row-clicked="rowClicked" @filtered="onFiltered">
                                <template #cell(actions)="row">
                                    <b-overlay :show="generating" opacity="0.7" spinner-small spinner-variant="primary"
                                        class="d-inline-block">
                                        <b-button size="sm" @click="certificateOfSeparation(row.item)" v-b-tooltip.hover
                                            title="Descargar constancia">
                                            <b-icon-download />
                                        </b-button>
                                    </b-overlay>
                                </template>
                            </b-table>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row class="mt-4 mb-5">
                    <b-col>
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="take" size="sm"
                            class="my-0 justify-content-end">
                            <template #first-text>
                                <span>
                                    <b-icon-chevron-double-left scale="1.3" />
                                </span>
                            </template>
                            <template #prev-text>
                                <span><b-icon-chevron-left scale="1.3" /></span>
                            </template>
                            <template #next-text>
                                <span><b-icon-chevron-right scale="1.3" /></span>
                            </template>
                            <template #last-text>
                                <span><b-icon-chevron-double-right scale="1.3" /></span>
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
            <b-row class="m-3 text-end">
                <b-col>
                    <b-button size="sm" variant="danger" class="px-3 ms-4" @click="close">
                        Cerrar
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>


<script>
import { RepositoryFactory } from "@/services/api";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
    name: "disable-legal-entities-list-modal",
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            search: "",
            filter: null,
            isBusy: false,
            fields: [
                { key: "name", label: "Nombre" },
                { key: "legalEntityType", label: "Tipo de persona jurídica" },
                { key: "creationDate", label: "Fecha de creación" },
                { key: "residentAgent.lawFirmName", label: "Agente residente" },
                { key: "actions", label: "Acción" },
            ],
            currentPage: 1,
            take: 10,
            rows: 1,
            generating: false,
        }
    },
    computed: {
        ...mapGetters("application", ["getEnabledCriteria"]),
        enabledCriteria() {
            return this.getEnabledCriteria;
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
        filterTable() {
            this.filter = this.search;
        },
        async retrieveData(context) {
            this.isBusy = true;
            try {
                let payload = {
                    page: context.currentPage,
                    take: 10,
                    search: context.filter,
                    enabled: false
                };
                if (this.enabledCriteria) {
                    payload.enabledCriteria = !this.enabledCriteria;
                }
                let response = await RepositoryFactory.legalEntity.getPaged(
                    payload
                );
                this.rows = response.data.total;
                let items = response.data.items;
                for (let i = 0; i < items.length; i++) {
                    const legalEntity = items[i];
                    legalEntity.creationDate = this.parseAndConvertDate(
                        legalEntity.creationDate
                    );
                    legalEntity.legalEntityType = this.getLegalEntityTypeString(
                        legalEntity.legalEntityType
                    );
                    legalEntity.residentAgent.lawFirmName = legalEntity.residentAgent.lawFirmName.toUpperCase()
                }
                return items;
            } catch (error) {
                window.console.log(error);
            }
            this.isBusy = false;
        },
        rowClicked(record) {
            console.log(record);
            /*             if (this.enabledCriteria !== true) {
                            this.$router.push({
                                name: "legal-entity",
                                params: { id: record.id },
                            });
                        } */
        },
        onFiltered(filteredItems) {
            this.rows = filteredItems.length;
            this.currentPage = 1;
        },
        parseAndConvertDate(date) {
            return moment.utc(date).local().format("DD/MM/YYYY HH:mm:ss");
        },
        getLegalEntityTypeString(type) {
            switch (type) {
                case 0:
                    return "Persona Jurídica con operaciones y/o activos fuera de Panamá";
                case 1:
                    return "Persona Jurídica con operaciones y/o activos en Panamá";
                case 2:
                    return "Persona jurídica listada en Bolsa de Valores";
                case 3:
                    return "Persona jurídica propiedad de una Entidad Estatal o Multilateral";
                case 4:
                    return "Persona jurídica propiedad de un Estado";
                case 5:
                    return "Persona jurídica con operaciones y/o activos en varias jurisdicciones incluyendo Panamá";
            }
            return "Type Not Found";
        },
        async certificateOfSeparation(item) {
            this.generating = true;
            try {
                let response = await RepositoryFactory.legalEntity.certificateOfSeparation({ id: item.id });

                if (response.data) {
                    window.console.log(response.data);
                    var fileURL = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    var fileLink = document.createElement("a");

                    fileLink.href = fileURL;
                    fileLink.setAttribute("download", "Certificate Of Separation Legal Entity.pdf");
                    document.body.appendChild(fileLink);

                    fileLink.click();

                    RepositoryFactory.event.add({
                        code: 2004,
                        details: "file download"
                    });
                }
            } catch (error) {
                window.console.log(error);
            } finally {
                this.generating = false;
            }
        }
    }
}
</script>