<template>
    <div class="mt-3 px-xl-5">
        <b-row>
            <h6 class="page-title pb-2 mt-3">LISTADO DE PERSONAS JURÍDICAS</h6>
        </b-row>
        <b-row class="justify-content-end mb-3">
            <b-col class="text-end">
                <b-button class="main-btn" @click="exports" :disabled="generating">Exportar</b-button>
                <b-button class="main-btn ms-4" @click="disengaged" v-b-tooltip.hover title="Desvinculados">
                    <b-icon-file-earmark-break scale="1.5" />
                </b-button>
                <b-button v-b-tooltip.hover title="Crear" v-if="allowFinalBeneficiary || enabledCriteria"
                    class="main-btn ms-4" @click="$router.push({ name: 'new-legal-entity' })">
                    <b-icon-person-plus scale="1.5" />
                </b-button>
            </b-col>
            <b-col xl="4" md="6" cols="6">
                <b-input-group class="mt-1">
                    <b-form-input placeholder="Buscar" class="rounded-0" size="sm" v-model="search"
                        @keydown.enter="filterTable" />
                    <b-input-group-append>
                        <b-button class="main-btn" size="sm" @click="filterTable">
                            <b-icon-search />
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card no-header no-body class="rounded-0 shadow-sm border-0">
                    <b-table stacked="md" small hover select-mode="single" selectable show-empty
                        empty-text="No hay personas jurídicas que mostrar" :filter="filter" :busy.sync="isBusy"
                        :fields="fields" :items="retrieveData" :current-page="currentPage" :per-page="take"
                        @row-clicked="rowClicked" @filtered="onFiltered">
                    </b-table>
                </b-card>
            </b-col>
        </b-row>
        <b-row class="mt-4 mb-5">
            <b-col>
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="take" size="sm"
                    class="my-0 justify-content-end">
                    <template #first-text>
                        <span>
                            <b-icon-chevron-double-left scale="1.3" />
                        </span>
                    </template>
                    <template #prev-text>
                        <span><b-icon-chevron-left scale="1.3" /></span>
                    </template>
                    <template #next-text>
                        <span><b-icon-chevron-right scale="1.3" /></span>
                    </template>
                    <template #last-text>
                        <span><b-icon-chevron-double-right scale="1.3" /></span>
                    </template>
                </b-pagination>
            </b-col>
        </b-row>

        <DisableLegalEntitiesListModal :isOpen="openedModal" @close="closeModal" />
    </div>
</template>

<script>
import moment from "moment";
import { RepositoryFactory } from "@/services/api";
import { mapGetters } from "vuex";
import DisableLegalEntitiesListModal from "./DisableLegalEntitiesListModal.vue";

export default {
    name: "legal-entities",
    components: {
        DisableLegalEntitiesListModal
    },
    data() {
        return {
            fields: [
                { key: "name", label: "Nombre" },
                { key: "legalEntityType", label: "Clasificación de la persona jurídica" },
                { key: "creationDate", label: "Fecha de creación" },
                { key: "residentAgent.lawFirmName", label: "Agente residente" },
                { key: "folioNumber", label: "Folio" },
                { key: "societyType", label: "Tipo de Sociedad" },
                { key: "residentAgent.name", label: "Registrado por" },
            ],
            search: "",
            filter: null,
            isBusy: false,
            currentPage: 1,
            take: 10,
            rows: 1,
            openedModal: false,
            generating: false,
        };
    },
    created() {
        let search = this.$route.query.search;
        if (search !== undefined) {
            this.search = search;
            this.filter = search;
        }
    },
    computed: {
        ...mapGetters("application", [
            "allowResidentAgentAdminFinalBeneficiaries",
            "getEnabledCriteria",
        ]),
        allowFinalBeneficiary() {
            return this.allowResidentAgentAdminFinalBeneficiaries;
        },
        enabledCriteria() {
            return this.getEnabledCriteria;
        },
    },
    methods: {
        filterTable() {
            this.filter = this.search;
        },
        onFiltered(filteredItems) {
            this.rows = filteredItems.length;
            this.currentPage = 1;
        },
        async retrieveData(context) {
            this.isBusy = true;
            try {
                let payload = {
                    page: context.currentPage,
                    take: 10,
                    search: context.filter,
                };
                if (this.enabledCriteria) {
                    payload.enabled = !this.enabledCriteria;
                    payload.enabledCriteria = this.enabledCriteria;
                }
                let response = await RepositoryFactory.legalEntity.getPaged(
                    payload
                );
                this.rows = response.data.total;
                let items = response.data.items;
                for (let i = 0; i < items.length; i++) {
                    const legalEntity = items[i];
                    legalEntity.creationDate = this.parseAndConvertDate(
                        legalEntity.creationDate
                    );
                    legalEntity.legalEntityType = this.getLegalEntityTypeString(
                        legalEntity.legalEntityType
                    );
                    legalEntity.residentAgent.lawFirmName = legalEntity.residentAgent.lawFirmName.toUpperCase()
                }
                return items;
            } catch (error) {
                window.console.log(error);
            }
            this.isBusy = false;
        },
        async exports() {
            try {
                this.generating = true;
                let payload = {
                    search: this.search,
                };
                if (this.enabledCriteria) {
                    payload.enabled = !this.enabledCriteria;
                    payload.enabledCriteria = this.enabledCriteria;
                }
                let response = await RepositoryFactory.legalEntity.export(payload);

                if (response.data) {
                    var fileURL = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    var fileLink = document.createElement("a");

                    fileLink.href = fileURL;
                    fileLink.setAttribute("download", "LegalEntities.xlsx");
                    document.body.appendChild(fileLink);

                    fileLink.click();
                }
            } catch (error) {
                window.console.log(error);
            } finally {
                this.generating = false;
            }
        },
        getLegalEntityTypeString(type) {
            switch (type) {
                case 0:
                    return "Persona Jurídica con operaciones y/o activos fuera de Panamá";
                case 1:
                    return "Persona Jurídica con operaciones y/o activos en Panamá";
                case 2:
                    return "Persona jurídica listada en Bolsa de Valores";
                case 3:
                    return "Persona jurídica propiedad de una Entidad Estatal o Multilateral";
                case 4:
                    return "Persona jurídica propiedad de un Estado";
                case 5:
                    return "Persona jurídica con operaciones y/o activos en varias jurisdicciones incluyendo Panamá";
            }
            return "Type Not Found";
        },
        parseAndConvertDate(date) {
            return moment.utc(date).local().format("DD/MM/YYYY HH:mm:ss");
        },
        rowClicked(record) {
            this.$router.push({
                name: "legal-entity",
                params: { id: record.id },
            });
        },
        disengaged() {
            this.openedModal = true;
        },
        closeModal() {
            this.openedModal = false;
        }
    },
};
</script>